import HeaderBarLayout from "../../layout/BaseLayout";
import MainBanner from "../../container/banner/index";
import { Box, Container, Typography } from "@mui/material";
import RentalSolution from "../../container/Rental";
import HowITWorks from "../../container/howitworks";
import WhyBlueCharger from "../../container/whyblue";
import Specification from "../../container/specification";
import Plan from "../../container/plan";
import FooterBackdrop from "../../container/FooterBackdrop";
import MapFooter from "../../container/MapFooter";
import Footer from "../../layout/Footer/index";
import HeaderNav from "../../layout/Header";
import { useEffect } from "react";
import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

export default function Homepage() {
  return (
    <Box>
      <HeaderNav />
      <MainBanner />
      <Box
        sx={{ background: "#FFFFFF", height: 100 }}
        display="flex"
        alignItems="center"
      >
        <Container>
          <Box display="flex" justifyContent="center">
            <Typography fontSize={32} fontWeight={700} color="#0E0E0E">
              Rent <span>.</span>{" "}
              <span style={{ color: "#4285F4" }}>Recharge</span>
              <span>.</span>
              <span>Return</span>
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box sx={{ mt: 0 }}>
        <RentalSolution />
      </Box>
      <Element name="howitworks" id="howitworks">
        <Box sx={{ mt: 3 }}>
          <HowITWorks />
        </Box>
      </Element>
      <Element name="whybluecharge" id="whybluecharge">
        <Box sx={{ mt: 3 }}>
          <WhyBlueCharger />
        </Box>
      </Element>
      <Element name="specification" id="specification">
        <Box sx={{ mt: 3 }}>
          {" "}
          <Specification />
        </Box>
      </Element>
      <Element name="plan" id="plan">
        <Box sx={{ mt: 3 }}>
          <Plan />
        </Box>
      </Element>

      <Box sx={{ mt: 3, mb: -3 }}>
        <FooterBackdrop />{" "}
      </Box>

      <Box sx={{ mt: 3 }}>
        <MapFooter />{" "}
      </Box>
      <Box sx={{ mt: 0 }}>
        <Footer />{" "}
      </Box>
    </Box>
  );
}
Homepage.getLayout = (page) => <HeaderBarLayout>{page}</HeaderBarLayout>;
