import { Box, Container, Typography, useMediaQuery } from "@mui/material";

const planArr = [
  {
    title: "PAY AS YOU GO",
    price: "₦500",
    desc: "1 off Swap ",
    isCurrentPlan: false,
  },
  {
    title: "SMART PRO",
    price: "₦4,000",
    desc: "Unlimited Swaps | 1 Week",
    isCurrentPlan: true,
  },
  {
    title: "PRO",
    price: "₦12,000",
    desc: "Unlimited Swaps | 1 Month",
    isCurrentPlan: false,
  },
];
export default function Plan() {
  const matches = useMediaQuery("(max-width:700px)");
  return (
    <Box
      sx={{ height: matches ? 1450 : 500 }}
      display="flex"
      justifyContent="center"
      p={matches ? 3 : 0}
    >
      <Container>
        <Box display="flex" justifyContent="center">
          <Box
            sx={{ width: 600 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              sx={{ m: "10px 0" }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography sx={{ fontSize: 36, fontWeight: 500 }}>
                Choose Your Plan
              </Typography>
              <Box sx={{ border: 2, width: 150, borderColor: "#4285F4" }} />
            </Box>
            <Box sx={{ m: "10px 0" }}>
              <Typography textAlign="center">
                We accept all major credit cards, debit cards and UPI. One-time
                refundable security deposit of ₦10,000 is required.{" "}
                <span style={{ fontWeight: 500 }}>
                  (applicable on all plan)
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ mt: 5 }}
          alignContent="center"
          display={matches ? "block" : "flex"}
          gap={0.7}
          ml={matches ? 1.5 : 0}
          justifyContent="center"
        >
          {planArr.map((plan, index) => (
            <Box
              key={index}
              sx={{
                height: 280,
                mb: matches ? 2 : 0,
                width: 300,
                borderRadius: 2,
                borderColor: "#888",
                boxShadow: "0px 3px 8px 0 #ccc",
                background: plan.isCurrentPlan ? "#296AAF" : "transparent",
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography
                fontSize={24}
                fontWeight={800}
                color={plan.isCurrentPlan ? "#FFFFFF" : ""}
              >
                {plan.title}
              </Typography>
              <Typography
                color={plan.isCurrentPlan ? "#FFFFFF" : "#296AAF"}
                fontSize={48}
                fontWeight={800}
              >
                {plan.price.split("/")[0]}
                <span style={{ fontSize: 12 }}>{plan.price.split("/")[1]}</span>
              </Typography>
              <Typography
                color={plan.isCurrentPlan ? "#FFFFFF" : "#555555"}
                fontSize={17}
                fontWeight={500}
              >
                {plan.desc}
              </Typography>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
