import {
  Avatar,
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import Carousel from "react-simply-carousel";

const ListArr = [
  {
    title: "Download the app",
    content:
      "Download the app available on the Google play store/ app store Then register with your mobile number and the app will navigate you to the nearest Blue Charge station",
    icon: "/static/images/download.png",
  },
  {
    title: "Scan the QR code",
    content:
      "Once you reach there, scan the QR code displayed on the machine, select the subscription plan as per your use to rent a powerbank",
    icon: "/static/images/qr-code.png",
  },
  {
    title: "Take the power bank",
    content:
      "Then, one fully charged portable battery will unlock and pop out of the station for you to take along.",
    icon: "/static/images/battery.png",
  },
  {
    title: "Easy Return",
    content:
      "Once you are done using the power bank, you can return it back to the nearest Bluecharge station",
    icon: "/static/images/return_pb.png",
  },
];
export default function HowITWorks() {
  const matches = useMediaQuery("(max-width:1200px)");
  const [index, setIndex] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);

  const images = [
    "/static/images/3.659b40e927416f321887.png",
    "/static/images/6.d6da7091c2197ce3d0b3.png",
    "/static/images/3.659b40e927416f321887.png",
    "/static/images/6.d6da7091c2197ce3d0b3.png",
    "/static/images/3.659b40e927416f321887.png",
    "/static/images/6.d6da7091c2197ce3d0b3.png",
  ];

  return (
    <Box sx={{ height: matches ? 1730 : 900, p: matches ? 3 : 0 }}>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ pt: matches ? 0 : 12 }}
        >
          <Typography fontSize={36} fontWeight={500}>
            How it Works
          </Typography>
          <Box sx={{ border: 2, width: 150, borderColor: "#4285F4" }} />
        </Box>
        <Box sx={{ mt: 5 }}>
          <Grid container spacing={2}>
            {ListArr.map((item, index) => (
              <Grid
                key={index}
                item
                lg={3}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <img width={150} height={"auto"} src={item.icon} alt="icon" />
                <Typography sx={{ mt: 2 }} fontSize={20} fontWeight={600}>
                  {item.title}
                </Typography>
                <Box sx={{ mt: 2.5 }}>
                  <Typography textAlign="center" color="#3A3737">
                    {item.content}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ mt: matches ? 2 : 10 }}>
          <div>
            <Carousel
              updateOnItemClick
              containerProps={{
                style: {
                  width: "100%",
                  justifyContent: "space-between",
                  userSelect: "none",
                },
              }}
              preventScrollOnSwipe
              swipeTreshold={60}
              activeSlideIndex={activeSlide}
              activeSlideProps={{
                style: {
                  background: "transparent",
                },
              }}
              onRequestChange={setActiveSlide}
              forwardBtnProps={{
                children: ">",
                style: {
                  width: 30,
                  height: 30,
                  minWidth: 30,
                  alignSelf: "center",
                  background: "#4285F4",
                  border: 0,
                  borderRadius: 10,
                  color: "#FFFFFF",
                },
              }}
              backwardBtnProps={{
                children: "<",
                style: {
                  width: 30,
                  height: 30,
                  minWidth: 30,
                  alignSelf: "center",
                  background: "#4285F4",
                  border: 0,
                  borderRadius: 10,
                  color: "#FFFFFF",
                },
              }}
              dotsNav={{
                show: true,
                itemBtnProps: {
                  style: {
                    height: 12,
                    width: 10,
                    borderRadius: "100%",
                    border: 0,
                    marginTop: 10,
                  },
                },
                activeItemBtnProps: {
                  style: {
                    height: 12,
                    width: 10,
                    borderRadius: "100%",
                    border: 0,
                    background: "#4285F4",
                    marginTop: 10,
                  },
                },
              }}
              responsiveProps={[
                { minWidth: 768, maxWidth: 2000, itemsToShow: 4 },
                { maxWidth: 767, itemsToShow: 1 },
              ]}
              // itemsToShow={2}
              speed={400}
              centerMode
            >
              {images.map((item, index) => (
                <Box key={index} sx={{ p: matches ? "0 5px" : "0 50px" }}>
                  <img src={item} width={150} height="auto" />
                </Box>
              ))}
            </Carousel>
          </div>
        </Box>
      </Container>
    </Box>
  );
}
