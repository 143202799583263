import { Box } from "@mui/material";

export default function MapFooter() {
  return (
    <Box sx={{ height: 450, mt: 0 }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d126094.9657075421!2d7.408844799999999!3d9.021030399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sng!4v1698879103753!5m2!1sen!2sng"
        width="100%"
        height="450"
        style={{ border: 0 }}
        // allowFullScreen=""
        loading="lazy"
        // referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>
  );
}
