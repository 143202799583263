import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
export default function Specification() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box sx={{ background: "#E5EFFF", p: matches ? 3 : 0 }}>
      <Box sx={{ height: matches ? 1420 : 1100, textAlign: "left" }}>
        <Container>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ pt: matches ? 2 : 5, mb: 8 }}
          >
            <Typography fontSize={36} fontWeight={500}>
              Specifications
            </Typography>
            <Box sx={{ border: 2, width: 150, borderColor: "#4285F4" }} />
          </Box>
          <Grid container spacing={0} display="flex" justifyContent="center">
            <Grid item lg={7} display="flex" justifyContent="center">
              <img
                src="/static/images/newcableimage.e3f2f2646da4b7c4a455.png"
                width={matches ? 300 : 500}
                height={matches ? 200 : 300}
                alt="specification"
              />
            </Grid>
            <Grid
              item
              lg={5}
              display="flex"
              justifyContent="start"
              alignItems="center"
            >
              <Box>
                <Typography fontWeight={600} fontSize={matches ? 35 : 50}>
                  Cables
                </Typography>
                <Box
                  sx={{ border: 2, width: 90, borderColor: "#4285F4" }}
                ></Box>
                <Box sx={{ mt: 3 }}>
                  <Typography>
                    Charge power banks come in white with 6000 mAH capacity with
                    inbuilt cables in it, which can charge micro, Type c & all
                    iOS devices. The power banks are sturdy enough to handle
                    extreme usage & conditions and are BIS-certified.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: matches ? 5 : 5 }}>
            <Grid
              container
              flexDirection={matches ? "column-reverse" : "inherit"}
            >
              <Grid
                item
                lg={6}
                display="flex"
                justifyContent="start"
                alignItems="center"
              >
                <Box sx={{ mt: matches ? 5 : 10 }}>
                  <Typography fontWeight={600} fontSize={matches ? 35 : 50}>
                    Powerbank
                  </Typography>
                  <Box
                    sx={{ border: 2, width: 90, borderColor: "#4285F4" }}
                  ></Box>
                  <Box sx={{ mt: 3 }}>
                    <Typography fontSize={24}>Features:</Typography>
                    <ul style={{}}>
                      <li style={{ listStyleType: "disc", marginBottom: 10 }}>
                        Smart charging and discharging circuit
                      </li>
                      <li style={{ listStyleType: "disc", marginBottom: 10 }}>
                        IoT enabled
                      </li>
                      <li style={{ listStyleType: "disc", marginBottom: 10 }}>
                        Intelligent hardware tracks that prevents it from
                        tampering and damage.
                      </li>
                      <li style={{ listStyleType: "disc", marginBottom: 10 }}>
                        BIS|CE| ROHS certificate.
                      </li>
                      <li style={{ listStyleType: "disc", marginBottom: 10 }}>
                        Power Requirement - Output: 5V/2.1A | Input: 5V/ 2A
                      </li>
                      <li style={{ listStyleType: "disc", marginBottom: 10 }}>
                        Battery - High-quality Li-battery with global insurance,
                        6,000mAh
                      </li>
                      <li
                        style={{
                          listStyleType: "disc",
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        NOTE: Powerbank can only be charged on the BlueCharge
                        console!
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} display="flex" justifyContent="center">
                <Box>
                  <img
                    src="/static/images/6.d6da7091c2197ce3d0b3.png"
                    width={matches ? 200 : 280}
                    height={matches ? 300 : 500}
                    alt="specification"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
