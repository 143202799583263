import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
export default function WhyBlueCharger() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box sx={{ height: matches ? 1420 : 750 }}>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ pt: 5, mb: 8 }}
        >
          <Typography fontSize={36} fontWeight={500}>
            Why BlueCharge
          </Typography>
          <Box sx={{ border: 2, width: 150, borderColor: "#4285F4" }} />
        </Box>
        <Box>
          <Grid container spacing={2} display="flex" justifyContent="center">
            <Grid item lg={3} display="flex" justifyContent="center">
              <Box sx={{ p: 3, pt: matches ? 0 : 3 }} textAlign="left">
                <Box sx={{ m: "10px 0", width: 220 }}>
                  <Typography fontWeight={600} fontSize={16}>
                    Cost Effective
                  </Typography>
                  <Typography fontSize={16}>
                    Rent quality Power banks at affordable prices.
                  </Typography>
                </Box>
                <Box sx={{ width: 100, borderBottom: 2 }}></Box>
                <Box sx={{ m: "20px 0", width: 220 }}>
                  <Typography fontWeight={600} fontSize={16}>
                    Easy Access
                  </Typography>
                  <Typography fontSize={16}>
                    Guided Navigation to rent & return.
                  </Typography>
                </Box>
                <Box sx={{ width: 100, borderBottom: 2 }}></Box>
                <Box sx={{ m: "20px 0", width: 220 }}>
                  <Typography fontWeight={600} fontSize={16}>
                    Stay Connected
                  </Typography>
                  <Typography fontSize={16}>
                    Located strategically for easy 24*7 availability
                  </Typography>
                </Box>
                <Box sx={{ width: 100, borderBottom: 2 }}></Box>
              </Box>
            </Grid>
            <Grid item lg={2.5} display="flex" justifyContent="center">
              <Box>
                <img
                  src="/static/images/whybluecharge.png"
                  width={matches ? 150 : 200}
                  height={matches ? 350 : 450}
                  alt="bluecharge"
                />
                <Box sx={{ background: "#E5EFFF" }}>
                  <Typography sx={{ p: 2, color: "red", fontStyle: "italic" }}>
                    Power Bank Stations screens for advertisement
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} display="flex" justifyContent="center">
              <Box sx={{ p: 3, textAlign: "left" }}>
                <Box sx={{ m: "20px 0", width: 220 }}>
                  <Typography fontWeight={600} fontSize={16}>
                    Easy Rent
                  </Typography>
                  <Typography fontSize={16}>
                    One click rent and zero-click Return.
                  </Typography>
                </Box>
                <Box sx={{ width: 100, borderBottom: 2 }}></Box>
                <Box sx={{ m: "20px 0", width: 220 }}>
                  <Typography fontWeight={600} fontSize={16}>
                    Travel Light
                  </Typography>
                  <Typography fontSize={16}>
                    No need to worry and carry your charged power banks or
                    charger.
                  </Typography>
                </Box>
                <Box sx={{ width: 100, borderBottom: 2 }}></Box>
                <Box sx={{ m: "20px 0", width: 220 }}>
                  <Typography fontWeight={600} fontSize={16}>
                    Go <span style={{ color: "yellowgreen" }}>Green !</span>
                  </Typography>
                  <Typography fontSize={16}>
                    &lsquo;Sharing Economy&lsquo; for a Sustainable Future
                  </Typography>
                </Box>
                <Box sx={{ width: 100, borderBottom: 2 }}></Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
