import { Box, Container, Typography } from "@mui/material";

export default function PrivacyLayout() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 5, mb: 5 }}>
        <Box>
          <Typography
            sx={{
              textAlign: "start",
              fontSize: 24,
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            Privacy and Policy
          </Typography>
        </Box>
        <Typography sx={{ textAlign: "start" }}>
          <p>
            Welcome to Blue and Blue Echarge ("Company," "we," "us," or "our").
            This Privacy Policy outlines how we collect, use, disclose, and
            safeguard your personal information when you use our Services. By
            accessing or using our Services, you consent to the practices
            described in this Privacy Policy. If you do not agree with our
            practices, please do not use our Services.
          </p>

          <h2>1. Information We Collect</h2>

          <ul>
            <li>
              <h3>1.1. Personal Information:</h3>
              <p>
                We may collect personal information such as your name, contact
                information (email address and phone number), and payment
                information when you create an account and use our Services.
              </p>
            </li>

            <li>
              <h3>1.2. Usage Information:</h3>
              <p>
                We may collect information about your use of our Services,
                including the power banks you borrow, the duration of your
                borrowings, and the location of the power bank.
              </p>
            </li>

            <li>
              <h3>1.3. Device Information:</h3>
              <p>
                We may collect information about the devices you use to access
                our Services, including device type, operating system, and
                unique device identifiers.
              </p>
            </li>

            <li>
              <h3>1.4. Location Information:</h3>
              <p>
                With your consent, we may collect precise location information
                to facilitate the provision of our Services. You can control
                location data in your device settings.
              </p>
            </li>

            <li>
              <h3>1.5. Communication:</h3>
              <p>
                We may collect information when you contact us for customer
                support, provide feedback, or participate in surveys.
              </p>
            </li>
          </ul>

          <h2>2. How We Use Your Information</h2>

          <ul>
            <li>
              <h3>2.1. Service Provision:</h3>
              <p>
                We use your personal information to provide and enhance our
                Services, such as allowing you to borrow power banks, processing
                payments, and communicating with you.
              </p>
            </li>

            <li>
              <h3>2.2. Customer Support:</h3>
              <p>
                We use your information to respond to your inquiries and provide
                customer support.
              </p>
            </li>

            <li>
              <h3>2.3. Improvement:</h3>
              <p>
                We may use your information to improve our Services, including
                troubleshooting issues, conducting research, and analyzing usage
                patterns.
              </p>
            </li>

            <li>
              <h3>2.4. Communication:</h3>
              <p>
                We may send you service-related notifications, updates, and
                promotional information based on your communication preferences.
              </p>
            </li>
          </ul>

          <h2>3. Sharing of Your Information</h2>

          <ul>
            <li>
              <h3>3.1. Service Providers:</h3>
              <p>
                We may share your information with trusted service providers who
                assist us in providing our Services, such as payment processors
                and delivery services.
              </p>
            </li>

            <li>
              <h3>3.2. Legal Requirements:</h3>
              <p>
                We may disclose your information to comply with legal
                obligations, respond to lawful requests, protect our rights,
                privacy, safety, or property, or in the case of an emergency.
              </p>
            </li>
          </ul>

          <h2>4. Your Choices</h2>

          <ul>
            <li>
              <h3>4.1. Account Information:</h3>
              <p>
                You can access, correct, or delete your account information at
                any time by contacting us.
              </p>
            </li>

            <li>
              <h3>4.2. Location Information:</h3>
              <p>
                You can control location data through your device settings or
                the settings in our app.
              </p>
            </li>

            <li>
              <h3>4.3. Promotional Communications:</h3>
              <p>
                You can opt out of receiving promotional communications from us
                by following the instructions in those messages.
              </p>
            </li>
          </ul>

          <h2>5. Security</h2>

          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access, disclosure, alteration, or destruction.
          </p>

          <h2>6. Changes to this Privacy Policy</h2>

          <p>
            We may update this Privacy Policy from time to time, and the updated
            Privacy Policy will be effective as of the date it is posted on our
            website or app.
          </p>

          <h2>7. Contact Information</h2>

          <p>
            If you have any questions or concerns regarding this Privacy Policy
            or your personal information, please contact us at{" "}
            <a href="www.blueecharge.com/contact">
              www.blueecharge.com/contact
            </a>
          </p>

          <p>
            By using our Services, you acknowledge that you have read,
            understood, and agreed to this Privacy Policy. Please review this
            Privacy Policy regularly to stay informed of any changes.
          </p>

          <p>Thank you for choosing Blue and Blue Echarge!</p>
        </Typography>
      </Box>
    </Container>
  );
}
