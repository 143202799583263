import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";

const blueCharge = [
  {
    title: " Plug and Play",
    desc: "Simply plug-in and it will set up within 5 minutes, and you are ready to go.",
  },
  {
    title: "Self Service",
    desc: "The best feature of the machine is that it’s automated, all you do is relax",
  },
  {
    title: "Portable Power",
    desc: "No need to get stuck in one place use the portable charger according to your convenience.",
  },
  {
    title: "Custom Branded",
    desc: "Get custom branding on our battery cover.",
  },
];
export default function BLueCharge() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box sx={{ height: matches ? 1250 : 600 }}>
      <Container>
        <Box
          sx={{ mt: matches ? 2 : 7 }}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Typography fontSize={36} fontWeight={500} textTransform="uppercase">
            Blue Charge
          </Typography>
          <Box sx={{ width: matches ? 320 : 650, textAlign: "center" }}>
            <Typography
              fontFamily="lato"
              fontSize={18}
              fontWeight={500}
              color="#555555"
            >
              Bluecharge does not take a single penny for maintaining the
              stations,Simply plug the station in and the app educates the
              users.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Grid container>
            <Grid item lg={6}>
              <Grid container>
                {blueCharge.map((item, index) => (
                  <Grid key={index} item lg={6}></Grid>
                ))}
              </Grid>
              <Box>
                <img
                  src="/static/images/blueCharge_ven.png"
                  width={matches ? 300 : 350}
                  height={matches ? 300 : 350}
                  alt="bluecharge"
                />
              </Box>
              <Box sx={{ width: matches ? 320 : 400 }}>
                <Typography fontFamily="lato" fontWeight={700}>
                  Perfect for Pubs, Bars, Hotels, Cafes, Restaurants, Hubs,
                  Parks and Offices
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} sx={{ mt: matches ? 4 : 0 }}>
              <Grid container spacing={3}>
                {blueCharge.map((item, index) => (
                  <Grid key={index} item lg={6} sx={{ mb: 3 }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={matches ? "center" : "inherit"}
                    >
                      <span style={{ marginRight: 10, marginTop: 10 }}>
                        <img
                          src="/static/logos/icons8_checked.svg"
                          width={30}
                          height={30}
                          alt="icon"
                        />
                      </span>
                      <Typography fontSize={24} fontWeight={500}>
                        {item.title}
                      </Typography>
                    </Box>
                    <Typography color="#555555" fontSize={16}>
                      {item.desc}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Box
                display="flex"
                alignItems="center"
                sx={{ mt: 3 }}
                justifyContent={matches ? "center" : "inherit"}
              >
                <span style={{ marginRight: 10, marginTop: 10 }}>
                  <img
                    src="/static/logos/icons8_checked.svg"
                    width={30}
                    height={30}
                    alt="icon"
                  />
                </span>
                <Typography fontSize={24} fontWeight={500}>
                  Plug and Play
                </Typography>
              </Box>
              <Typography color="#555555" fontSize={16}>
                - 6/12 x 5000mAh BlueCharge power banks
              </Typography>
              <Typography color="#555555" fontSize={16}>
                - 1 x BlueCharge Station
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
