import LogoMain from "../../components/Logo/LogoMain";
import { Nav } from "../../utils/nav";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, Element, Events, scrollSpy, scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
export default function HeaderNav() {
  const matches = useMediaQuery("(max-width:1200px)");
  scrollSpy.update();
  const navigate = useNavigate();

  const handleNavigation = (event) => {
    navigate(event);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        p: 2,
        boxShadow: "2px 4px 15px 0px rgba(0, 0, 0, 0.08)",
        position: "sticky",
        top: 0,
        background: "#FFFFFF",
        zIndex: 2000,
      }}
    >
      <Container>
        <Box display="flex">
          <Grid
            container
            spacing={0}
            display="flex"
            justifyContent={matches ? "center" : "space-between"}
          >
            <Grid item lg={matches ? 10 : 3.5}>
              <Box sx={{ mr: matches ? -7 : 0 }}>
                <LogoMain />
              </Box>
            </Grid>
            <Grid
              item
              lg={matches ? 2 : 8.5}
              display="flex"
              justifyContent="end"
              alignItems="center"
            >
              {!matches && (
                <List sx={{ display: "flex" }}>
                  {Nav.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{ width: "auto", cursor: "pointer" }}
                    >
                      {item.label === "Venues" ? (
                        <a
                          href={item.href}
                          className="scrollto"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography
                            fontWeight={700}
                            noWrap
                            sx={{
                              borderBottom: "2px solid transparent",
                              "&:before": {
                                content: '""',
                                position: "absolute",
                                width: 0,
                                height: "2px",
                                bottom: 5,
                                left: 19,
                                background: "#296AAF",
                                transition: "width 0.3s ease-in-out",
                              },
                              "&:hover:before": {
                                borderBottom: 2,
                                borderColor: "#296AAF",
                                width: "70%",
                              },
                            }}
                          >
                            {item.label}
                          </Typography>
                        </a>
                      ) : (
                        <Link
                          to={item.href.split("#")[1]}
                          smooth={true}
                          duration={800}
                          onClick={() => handleNavigation(item.href)}
                        >
                          <Typography
                            fontWeight={700}
                            noWrap
                            sx={{
                              borderBottom: "2px solid transparent",
                              "&:before": {
                                content: '""',
                                position: "absolute",
                                width: 0,
                                height: "2px",
                                bottom: 5,
                                left: 19,
                                background: "#296AAF",
                                transition: "width 0.3s ease-in-out",
                              },
                              "&:hover:before": {
                                borderBottom: 2,
                                borderColor: "#296AAF",
                                width: "70%",
                              },
                            }}
                          >
                            {item.label}
                          </Typography>
                        </Link>
                      )}
                    </ListItem>
                  ))}
                </List>
              )}

              {!matches && (
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      background: "#296AAF",
                      color: "#FFFFFF",
                      p: 1,
                      fontSiz: 15,
                      textTransform: "inherit",
                    }}
                  >
                    Become a station agent
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", alignItems: "center", mr: -2 }}>
            {matches && (
              <>
                <>
                  <Box sx={{ zIndex: 3000, flex: 1 }}>
                    <Tooltip title="">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <Avatar sx={{ width: 32, height: 32 }}>
                          <MenuIcon />
                        </Avatar>
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem onClick={() => handleNavigation("/#howitworks")}>
                      How it Works
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleNavigation("/#whybluecharge")}
                    >
                      Why BlueCharge
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleNavigation("/#specification")}
                    >
                      Specification
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("/#plan")}>
                      Plan
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("/venues")}>
                      Venues
                    </MenuItem>
                  </Menu>
                </>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
