import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";

export default function VenueBackdrop() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box
      sx={{
        height: 485,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: 'url("/static/images/venue.png")',
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        color: "#FFFFFF",
        backgroundPosition: "center", // Center the background image
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(20, 29, 45, 0.7)",
        },
        zIndex: 1000,
      }}
    >
      <Container>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{ zIndex: 1200, position: "absolute" }}
        >
          <Box
            sx={{ mt: -10 }}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              fontSize={matches ? 32 : 46}
              fontWeight={800}
              sx={{ color: "#FFFFFF" }}
            >
              Become BlueCharge Venue
            </Typography>
            <Typography
              fontSize={matches ? 16 : 22}
              fontWeight={500}
              sx={{ color: "#FFFFFF" }}
            >
              Your customer wants to stay connected & BlueCharge is the most
              convenient way to keep their phone charged
            </Typography>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Button
              sx={{
                background: "#296AAF",
                width: matches ? 200 : 382,
                height: 60,
                borderRadius: 2,
                textTransform: "inherit",
                color: "#FFFFFF",
                fontSize: matches ? 14 : 20,
                ":hover": {
                  background: "#296AAF",
                },
              }}
            >
              Become a station agent
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
