import { Box } from "@mui/material";
import PrivacyLayout from "../../container/privacy";
import MapFooter from "../../container/MapFooter";
import Footer from "../../layout/Footer";
import HeaderNav from "../../layout/Header";
import VenueBackdrop from "../../container/venue/backdrop";

export default function Privacy() {
  return (
    <Box>
      <HeaderNav />
      <VenueBackdrop />
      <PrivacyLayout />
      <Box>
        <MapFooter />
      </Box>
      <Footer />
    </Box>
  );
}
