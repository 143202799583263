import { Box } from "@mui/material";
import MapFooter from "../../container/MapFooter";
import Footer from "../../layout/Footer";
import HeaderNav from "../../layout/Header";
import VenueBackdrop from "../../container/venue/backdrop";
import TermsLayout from "../../container/terms";

export default function Terms() {
  return (
    <Box>
      <HeaderNav />
      <VenueBackdrop />
      <TermsLayout />
      <Box>
        <MapFooter />
      </Box>
      <Footer />
    </Box>
  );
}
