import {
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  OutlinedInput,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";

export default function RequestStation() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box sx={{ height: matches ? 1300 : 793 }}>
      <Container>
        <Box sx={{ p: 4 }} display="flex" justifyContent="center">
          <Typography fontSize={36} fontWeight={500}>
            Request a Station
          </Typography>
        </Box>
        <Box>
          <Grid
            container
            spacing={5}
            display="flex"
            justifyContent={matches ? "center" : "inherit"}
          >
            <Grid
              item
              lg={6}
              display="flex"
              alignItems="end"
              flexDirection="column"
            >
              <Box sx={{ mb: 4 }}>
                <InputLabel>Full Name</InputLabel>
                <OutlinedInput
                  sx={{
                    width: matches ? 330 : 350,
                    border: 0,
                    background: "#F1F1F1",
                    m: "10px 0",
                  }}
                  placeholder="Your Name"
                />
              </Box>
              <Box sx={{ mb: 4 }}>
                <InputLabel>Email Address:</InputLabel>
                <OutlinedInput
                  sx={{
                    width: matches ? 330 : 350,
                    border: 0,
                    background: "#F1F1F1",
                    m: "10px 0",
                  }}
                  placeholder="Enter Your Email Id"
                />
              </Box>
              <Box sx={{ mb: 4 }}>
                <InputLabel>Street Address, City:</InputLabel>
                <OutlinedInput
                  sx={{
                    width: matches ? 330 : 350,
                    border: 0,
                    background: "#F1F1F1",
                    m: "10px 0",
                  }}
                  placeholder="Street Address, City"
                />
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              display="flex"
              alignItems="start"
              flexDirection="column"
            >
              <Box sx={{ mb: 4 }}>
                <InputLabel>Business / Venue Name:</InputLabel>
                <OutlinedInput
                  sx={{
                    width: matches ? 330 : 350,
                    border: 0,
                    background: "#F1F1F1",
                    m: "10px 0",
                  }}
                  placeholder="Venue Name"
                />
              </Box>
              <Box sx={{ mb: 4 }}>
                <InputLabel>Phone Number:</InputLabel>
                <OutlinedInput
                  sx={{
                    width: matches ? 330 : 350,
                    border: 0,
                    background: "#F1F1F1",
                    m: "10px 0",
                  }}
                  placeholder="Enter Your Mobile No"
                />
              </Box>
              <Box sx={{ mb: 4 }}>
                <InputLabel>Post Code:</InputLabel>
                <OutlinedInput
                  sx={{
                    width: matches ? 330 : 350,
                    border: 0,
                    background: "#F1F1F1",
                    m: "10px 0",
                  }}
                  placeholder="Post Code"
                />
              </Box>
            </Grid>
            <Grid
              item
              lg={12}
              sx={{ mt: -5 }}
              display="flex"
              justifyContent="center"
            >
              <Box display="flex" alignItems="start" flexDirection="column">
                <InputLabel>More Info (Business / Venue Type etc):</InputLabel>
                <TextareaAutosize
                  maxRows={3}
                  style={{ width: matches ? 330 : "730px", height: 100 }}
                />
                <Button
                  sx={{
                    width: matches ? 330 : "730px",
                    height: "58px",
                    background: "#4285F4",
                    color: "#fff",
                    ":hover": { background: "#4285F4" },
                    mt: 2,
                  }}
                >
                  Submit Message
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "730px" }}>
            <Typography color="#555555" sx={{ textAlign: "justify" }}>
              By clicking Submit, you indicate that you have read and understand
              our <span style={{ color: "#000000" }}>Privacy Policy and</span>{" "}
              <span style={{ color: "#000000" }}>Terms of Use</span> and
              represent that you are at least 18 years of age.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
