import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";

const responsibilityArr = [
  "Free Delivery",
  " 24/7 customer service (we handle all user questions via our app so your staff don’t have to)",
  " Restocking batteries & station maintenance",
  " Advertising your venue via BLUECHARGE  charge app",
  " Provide free marketing material",
];
export default function Responsibility() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box sx={{ height: matches ? 750 : 400, background: "#E5EFFF" }}>
      <Container>
        <Box sx={{ pt: 10 }}>
          <Grid container>
            <Grid item lg={8}>
              <Typography
                fontSize={36}
                fontWeight={500}
                textAlign={matches ? "center" : "left"}
              >
                BLUE CHARGE Responsibilities
              </Typography>
              <Box>
                <List>
                  {responsibilityArr.map((item, index) => (
                    <ListItem key={index} sx={{ p: " 0" }}>
                      <span style={{ marginRight: 10, marginTop: 5 }}>
                        <img
                          src="/static/logos/icons8_checked.svg"
                          width={25}
                          height={25}
                          alt="icon"
                        />
                      </span>
                      <Typography>{item}</Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Typography fontSize={36} fontWeight={500}>
                Venue Responsibilities
              </Typography>
              <List>
                <ListItem sx={{ p: " 0" }}>
                  <span style={{ marginRight: 10, marginTop: 5 }}>
                    <img
                      src="/static/logos/icons8_checked.svg"
                      width={25}
                      height={25}
                      alt="icon"
                    />
                  </span>
                  <Typography>Plug socket</Typography>
                </ListItem>
                <ListItem sx={{ p: " 0" }}>
                  <span style={{ marginRight: 10, marginTop: 5 }}>
                    <img
                      src="/static/logos/icons8_checked.svg"
                      width={25}
                      height={25}
                      alt="icon"
                    />
                  </span>
                  <Typography>Visible location (such as on the bar)</Typography>
                </ListItem>
                <ListItem sx={{ p: " 0" }}>
                  <span style={{ marginRight: 10, marginTop: 5 }}>
                    <img
                      src="/static/logos/icons8_checked.svg"
                      width={25}
                      height={25}
                      alt="icon"
                    />
                  </span>
                  <Typography>Keep the station turned on</Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
