import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";

const largeStation = [
  {
    title: "Freestanding",
    desc: "Our machine requires no mounting or installation, simply plug it in.",
  },
  {
    title: "Self Service",
    desc: "The best feature of the machine is that it’s automated, all you do is relax",
  },

  {
    title: "Custom Branded",
    desc: "Get custom branding on our battery cover.",
  },
  {
    title: "Portable Power",
    desc: "No need to get stuck in one place use the portable charger according to your convenience.",
  },
  {
    title: "Branding Opportunity",
    desc: "Get your brand promoted at our machine display to get customer engagement.",
  },
];
export default function LargeStation() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box sx={{ height: matches ? 1180 : 750 }}>
      <Container>
        <Box
          sx={{ mt: 4 }}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Typography fontSize={36} fontWeight={500} textTransform="uppercase">
            Large Station
          </Typography>
        </Box>
        <Box sx={{ mt: matches ? 2 : 6 }}>
          <Grid
            container
            display="flex"
            justifyContent={matches ? "center" : "inherit"}
          >
            <Grid item lg={6}>
              <List>
                {largeStation.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{ flexDirection: "column", alignItems: "start" }}
                  >
                    <Box display="flex" alignItems="center">
                      <span style={{ marginRight: 10, marginTop: 10 }}>
                        <img
                          src="/static/logos/icons8_checked.svg"
                          width={30}
                          height={30}
                          alt="icon"
                        />
                      </span>
                      <Typography>{item.title}</Typography>
                    </Box>
                    <Typography>{item.desc}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid
              item
              lg={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box
                display="flex"
                gap={3}
                alignItems="end"
                justifyContent="center"
              >
                <Box>
                  <img
                    src="/static/images/whybluecharge.png"
                    width={matches ? 150 : 200}
                    height={matches ? 350 : 500}
                    alt="bluecharge"
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                sx={{ width: matches ? 340 : 400, mt: 3 }}
              >
                <Typography
                  fontFamily="lato"
                  fontWeight={600}
                  color="#555555"
                  fontSize={18}
                  textAlign="center"
                >
                  Perfect for Cinemas, Shopping Centres, Train stations,
                  Airports, and large venues.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
