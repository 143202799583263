import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";

export default function FooterBackdrop() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box
      sx={{
        height: 580,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: 'url("/static/images/image3.jpg")',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "#FFFFFF",
        backgroundPosition: "center", // Center the background image
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(52, 52, 52, 0.77)",
        },
        zIndex: 1000,
      }}
      p={matches ? 2 : 0}
      display="flex"
      justifyContent="center"
    >
      <Container>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{ zIndex: 1200, position: "relative" }}
        >
          <Box
            sx={{ mt: -10 }}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              fontSize={matches ? 32 : 46}
              fontWeight={500}
              sx={{
                color: "#FFFFFF",
                textAlign: matches ? "center" : "inherit",
              }}
            >
              Find a BlueCharge Station
            </Typography>
            <Typography
              fontSize={matches ? 16 : 22}
              fontWeight={400}
              sx={{
                color: "#FFFFFF",
                mt: matches ? 1 : 0,
                textAlign: matches ? "center" : "inherit",
              }}
            >
              Stay connected. BlueCharge is the best way to keep your phone
              charged
            </Typography>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Button
              sx={{
                background: "#296AAF",
                // width: matches ? 200 : 382,
                height: matches ? 45 : 60,
                p: "0 20px",
                borderRadius: 2,
                border: 1,
                borderColor: "#FFFFFF",
                textTransform: "inherit",
                color: "#FFFFFF",
                fontSize: matches ? 14 : 20,
                ":hover": {
                  background: "#296AAF",
                },
              }}
            >
              Search for Charge station
            </Button>
          </Box>
          <Box
            sx={{ mt: 4 }}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography sx={{ fontSize: matches ? 25 : 50, fontWeight: 500 }}>
              Download BlueCharge Now
            </Typography>
            <Typography
              sx={{
                fontSize: matches ? 19 : 28,
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              Available on App Store
            </Typography>
          </Box>
          <Box display="flex" sx={{ mt: 4 }} gap={3}>
            <Box>
              <img
                src="/static/images/applestorehigh.3e029ef04013fa8bd8bb.png"
                width="auto"
                height={50}
                alt="andriod"
              />
            </Box>
            <Box>
              <img
                src="/static/images/googlestorehigh copy.edf6776666917f4e6cf5.png"
                width="auto"
                height={50}
                alt="andriod"
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
