import { Box, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

// import { useNavigate } from "react-router-dom";

export default function LogoMain() {
  const navigate = useNavigate();

  // const router = useNavigate();
  const handleViewHome = () => {
    navigate("/");
  };
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box display="flex" justifyContent={matches ? "center" : "start"}>
      <Box
        sx={{ width: 200, cursor: "pointer" }}
        display="flex"
        onClick={handleViewHome}
      >
        <img
          src="/static/logos/logoMain.png"
          width={200}
          height={"auto"}
          alt="logo"
        />
        <Box sx={{ ml: -1, mt: 2, transform: "rotate(20deg)" }}>
          <img
            src="/static/images/2930411.png"
            width={30}
            height={"auto"}
            alt="icon"
          />
        </Box>
      </Box>
    </Box>
  );
}
