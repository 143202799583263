import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
export default function MainBanner() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box
      sx={{
        height: matches ? 1050 : 450,
        background: "#296AAF",
        mt: -9.7,
        textAlign: "left",
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ m: matches ? 0 : 2, mt: 10 }}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            flexDirection={matches ? "column-reverse" : "inherit"}
          >
            <Grid item lg={6}>
              <Box sx={{ p: "30px 0", color: "#FFFFFF" }}>
                <Typography
                  //  sx={{ ml: matches ? 2 : 0 }}
                  fontWeight={700}
                  fontSize={48}
                  lineHeight={1}
                  textAlign={matches ? "center" : "inherit"}
                >
                  CHARGING MADE EASY!
                </Typography>
                <Typography
                  sx={{ m: matches ? 2 : 0 }}
                  fontSize={18}
                  lineHeight={1.8}
                ></Typography>
                <Typography
                  sx={{ m: matches ? 2 : 0 }}
                  fontSize={matches ? 18 : 20}
                  lineHeight="40px"
                >
                  BLUECHARGE- A Power Bank Rental service has brought a
                  revolution in the power bank market with its up-to-date
                  technology which qualifies all safety standards and sleek
                  design that can be found at multiple strategically located
                  stations that are operational 24/7 across the country
                </Typography>
                <Box
                  display="flex"
                  sx={{ mt: 4 }}
                  gap={3}
                  justifyContent={matches ? "center" : "inherit"}
                >
                  <Box>
                    <img
                      src="/static/images/applestorehigh.3e029ef04013fa8bd8bb.png"
                      width="auto"
                      height={50}
                      alt="andriod"
                    />
                  </Box>
                  <Box>
                    <img
                      src="/static/images/googlestorehigh copy.edf6776666917f4e6cf5.png"
                      width="auto"
                      height={50}
                      alt="andriod"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box display="flex" justifyContent={matches ? "center" : "end"}>
                <img
                  src="/static/images/main_bg.png"
                  width={matches ? 300 : 550}
                  height={matches ? 300 : 470}
                  alt="bluecharge"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
