import { Box, Container, Typography } from "@mui/material";

export default function TermsLayout() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 5, mb: 5 }}>
        <Box>
          <Typography
            sx={{
              textAlign: "start",
              fontSize: 24,
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            Terms and Conditions
          </Typography>
        </Box>
        <Typography sx={{ textAlign: "start" }}>
          <div>
            <h2>Blue and Blue Echarge Terms of Service</h2>

            <p>
              Welcome to Blue and Blue Echarge ("Company," "we," "us," or
              "our"). These Terms of Service ("Terms") govern your use of the
              services provided by Blue and Blue Echarge, including but not
              limited to borrowing and returning power banks ("Services"). By
              accessing or using our Services, you agree to comply with and be
              bound by these Terms. If you do not agree to these Terms, please
              do not use our Services.
            </p>

            <h3>1. Eligibility</h3>
            <p>1.1. You must be at least 18 years old to use our Services.</p>
            <p>
              1.2. By using our Services, you represent and warrant that you
              have the legal capacity to enter into a contract and that you will
              only use our Services in compliance with these Terms and all
              applicable laws and regulations.
            </p>

            <h3>2. Use of Services</h3>
            <p>
              2.1. Blue and Blue Echarge offers power banks for lending. You can
              borrow a power bank for a specified time period and return it in
              the same condition.
            </p>
            <p>
              2.2. Users are required to create an account to access our
              Services. You are responsible for maintaining the confidentiality
              of your account information, including your username and password.
            </p>
            <p>
              2.3. You agree to use the power banks provided by Blue and Blue
              Echarge responsibly and for their intended purpose. You must not
              misuse, damage, or steal any power banks.
            </p>
            <p>
              2.4. The power banks are provided on a first-come, first-served
              basis, subject to availability.
            </p>
            <p>
              2.5. You agree to return the power bank within the agreed-upon
              time. Late returns may result in additional charges.
            </p>

            <h3>3. Fees and Payments</h3>
            <p>
              3.1. The fees for using our Services are specified at the time of
              booking. You agree to pay all fees associated with your use of our
              Services.
            </p>
            <p>
              3.2. We may change our fees at any time, but such changes will not
              affect any ongoing bookings.
            </p>
            <p>
              3.3. Payments are processed securely through the payment methods
              available on our platform.
            </p>

            <h3>4. Cancellation and Refunds</h3>
            <p>
              4.1. You may cancel a booking before the scheduled pickup time and
              may be eligible for a refund, subject to our refund policy.
            </p>
            <p>
              4.2. In the event of a power bank malfunction or other issues,
              please contact us for assistance.
            </p>
            <h3>5. User Responsibilities</h3>
            <p>
              5.1. You are responsible for the power bank while it is in your
              possession. If the power bank is lost, stolen, or damaged due to
              your negligence or misuse, you may be responsible for replacement
              or repair costs.
            </p>
            <p>
              5.2. You agree to follow any instructions provided by Blue and
              Blue Echarge regarding the proper use of the power banks.
            </p>

            <h3>6. Privacy</h3>
            <p>
              6.1. We collect and use your personal information as described in
              our Privacy Policy. By using our Services, you consent to the
              collection and use of your information in accordance with our
              Privacy Policy.
            </p>

            <h3>7. Termination</h3>
            <p>
              7.1. We reserve the right to suspend or terminate your account and
              access to our Services if you violate these Terms or engage in any
              fraudulent, illegal, or improper activities.
            </p>
            <p>
              7.2. You may terminate your account at any time by contacting us.
            </p>

            <h3>8. Changes to Terms</h3>
            <p>
              8.1. We may update these Terms from time to time, and the updated
              Terms will be effective as of the date they are posted on our
              website or app.
            </p>

            <h3>9. Contact Information</h3>
            <p>
              If you have any questions or concerns regarding these Terms or our
              Services, please contact us at www.blueecharge.com/contact
            </p>

            <p>
              By using our Services, you acknowledge that you have read,
              understood, and agreed to these Terms of Service. Please review
              these Terms regularly to stay informed of any changes.
            </p>

            <p>Thank you for choosing Blue and Blue Echarge!</p>
          </div>
        </Typography>
      </Box>
    </Container>
  );
}
