import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import VenueBackdrop from "../../container/venue/backdrop";
import VenueBenefits from "../../container/venue/benefits";
import BlueCharge from "../../container/venue/bluecharge";
import LargeStation from "../../container/venue/largestation";
import Responsibility from "../../container/venue/responsibility";
import RequestStation from "../../container/venue/requeststation";
import MapFooter from "../../container/MapFooter";
import Footer from "../../layout/Footer";
import HeaderNav from "../../layout/Header";
export default function VenuePage() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box>
      <HeaderNav />
      <VenueBackdrop />
      <Box
        sx={{ background: "#FFFFFF", height: 100 }}
        display="flex"
        alignItems="center"
      >
        <Container>
          <Box display="flex" justifyContent="center">
            <Typography fontSize={32} fontWeight={700} color="#0E0E0E">
              Rent <span>.</span>{" "}
              <span style={{ color: "#4285F4" }}>Recharge</span>
              <span>.</span>
              <span>Return</span>
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box>
        <VenueBenefits />
      </Box>
      <Box>
        <BlueCharge />
      </Box>
      {/* <Box sx={{ mt: 12 }}>
        <Box
          justifyContent="center"
          alignItems="center"
          sx={{ height: 100, background: "#296AAF", display: "flex" }}
          gap={matches ? 3 : 10}
        >
          <Typography
            fontSize={matches ? 18 : 36}
            fontWeight={600}
            color="#FFFFFF"
          >
            REQUEST A STATION
          </Typography>
          <Button
            sx={{
              width: matches ? 150 : 180,
              height: matches ? 40 : 50,
              borderRadius: 10,
              background: "#FFFFFF",
              ":hover": {
                background: "#FFFFFF",
              },
            }}
          >
            ORDER NOW
          </Button>
        </Box>
      </Box> */}
      <Box>
        <LargeStation />
      </Box>
      <Box>
        <Responsibility />
      </Box>
      <Box>
        <RequestStation />
      </Box>
      <Box>
        <MapFooter />
      </Box>
      <Footer />
    </Box>
  );
}
// VenuePage.getLayout = (page) => <HeaderBarLayout>{page}</HeaderBarLayout>;
