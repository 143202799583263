import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";

export default function Footer() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box
      sx={{
        height: matches ? 800 : 400,
        background: "#0E0E0E",
        p: matches ? 3 : 0,
        pt: 0,
        textAlign: "left",
      }}
    >
      <Container>
        <Box sx={{ pt: 5 }}>
          <Grid container>
            <Grid item lg={4}>
              <Box sx={{ mb: 2 }}>
                <Typography fontSize={24} fontWeight={600} color="#FFFFFF">
                  Our Presence
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography fontSize={24} fontWeight={600} color="#FFFFFF">
                  BLUECHARGE
                </Typography>
              </Box>
              <Box sx={{ mb: 1 }}>
                <List sx={{ p: "5px 0", color: "#FFFFFF" }}>
                  <ListItem sx={{ p: "5px 0" }}>Lagos</ListItem>
                  <ListItem sx={{ p: "5px 0" }}>Abuja</ListItem>
                  <ListItem sx={{ p: "5px 0" }}>Port- Harcourt</ListItem>
                </List>
              </Box>
              <Box sx={{ mb: 1, color: "#FFFFFF" }}>
                <Typography>Customer Support - 0805 285 4218</Typography>
                <Typography>Email - support@blueecharge.com</Typography>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box sx={{ mb: 1 }}>
                <Typography fontSize={24} fontWeight={600} color="#FFFFFF">
                  The Company
                </Typography>
              </Box>
              <Box sx={{ mb: 1 }}>
                <List sx={{ p: "5px 0", color: "#FFFFFF" }}>
                  <ListItem sx={{ p: "5px 0" }}>How is Works</ListItem>
                  <ListItem sx={{ p: "5px 0" }}>Specification</ListItem>
                  <a href="/venues">
                    <ListItem sx={{ p: "5px 0" }}>Venues</ListItem>
                  </a>
                  <ListItem sx={{ p: "5px 0" }}>FAQs</ListItem>
                  <ListItem sx={{ p: "5px 0" }}>Events</ListItem>
                  <a href="/term-of-use">
                    <ListItem sx={{ p: "5px 0" }}>Terms & Condition</ListItem>
                  </a>
                  <a href="/privacy-policy">
                    <ListItem sx={{ p: "5px 0" }}>Privacy Policy</ListItem>
                  </a>
                </List>
              </Box>
            </Grid>
            <Grid item lg={4}></Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
