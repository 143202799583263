import {
  Avatar,
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

const benefit = [
  {
    title: "Customer Experience",
    desc: "Blue charge allows your customer & visitors to live assured, with a power bank or a fully charged phone.",
    icon: "/static/logos/mdi_customer-service.svg",
  },
  {
    title: " On Battery Branding",
    desc: "We have provisions to have partner branding on Batteries.",
    icon: "/static/logos/mdi_home-battery-outline.svg",
  },

  {
    title: "Increase Dwell Time",
    desc: "Stay longer at the venue without having low battery anxiety.",
    icon: "/static/logos/vaadin_hourglass.svg",
  },
  {
    title: "Promote Your Offers On Our App",
    desc: "You are welcome to promote your latest ads on our app and also display different promotions at a different location.",
    icon: "/static/logos/tabler_discount.svg",
  },
  {
    title: "Increased Footfall",
    desc: " Bluecharge app directs customers straight to your door with our in-app directions, fully integrated with Google Maps and City mapper",
    icon: "/static/logos/ion_footsteps-outline.svg",
  },
  {
    title: "Innovative Solution",
    desc: "Now with Bluecharge have a new yet fantastic experience. Travel light from one place to another",
    icon: "/static/logos/Group.svg",
  },
];
export default function VenueBenefits() {
  const matches = useMediaQuery("(max-width:1200px)");
  // #E5EFFF #EFEFEF
  return (
    <Box
      sx={{ background: "#E5EFFF", height: matches ? 1800 : 850 }}
      display="flex"
      alignItems="center"
    >
      <Container>
        <Grid container spacing={3}>
          {benefit.map((item, index) => (
            <Grid
              item
              lg={4}
              key={index}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Avatar sx={{ width: 120, height: 120, background: "#FFFFFF" }}>
                <img src={item.icon} width={80} height={80} alt="logo" />
              </Avatar>
              <Box
                sx={{ mt: 4 }}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Typography
                  fontSize={24}
                  textTransform="uppercase"
                  color="#2C2C2C"
                  textAlign="center"
                  fontWeight={600}
                >
                  {item.title}
                </Typography>
                <Typography fontSize={16} color="#2C2C2C" textAlign="center">
                  {item.desc}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
