import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
export default function RentalSolution() {
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Box
      sx={{
        mt: 2,
        background: "#E5EFFF",
        height: matches ? 950 : 700,
        p: matches ? 3 : 0,
      }}
    >
      <Container>
        <Box>
          <Grid
            container
            spacing={2}
            display="flex"
            justifyContent={matches ? "center" : "inherit"}
          >
            <Grid item lg={6} sx={{ mt: 5 }}>
              <img
                src="/static/images/rentals.png"
                width={matches ? 280 : 400}
                height={matches ? 300 : 500}
                alt="bluecharge"
              />
            </Grid>
            <Grid item lg={6} sx={{ mt: matches ? 2 : 5 }}>
              <Typography
                fontSize={32}
                fontWeight={500}
                textTransform="uppercase"
                textAlign={matches ? "center" : "inherit"}
              >
                Rental Solution
              </Typography>
              <Box sx={{ ml: 0, mt: 3 }}>
                <Typography
                  sx={{ fontSize: matches ? 28 : 36, fontWeight: 700 }}
                >
                  Rent | Recharge | Return
                </Typography>
                <Box>
                  <Typography fontSize={16} color="#555555" textAlign="left">
                    Away from home? And your cell phone is going to be dead in
                    minutes, well not to worry, BlueCharge has a solution to
                    your problem.
                  </Typography>
                  <Typography
                    fontSize={16}
                    color="#555555"
                    sx={{ mt: 2, textAlign: "left" }}
                  >
                    Rent a power bank, charge on the go and then return it back
                    to your nearest Blue Charge station
                  </Typography>
                </Box>
                <Box sx={{ background: "#FBFAFA", p: 4, mt: 4, ml: 0 }}>
                  <Typography
                    color="#4285F4"
                    fontStyle="italic"
                    fontSize={matches ? 20 : 24}
                    fontWeight={500}
                  >
                    Make your charging routine easier by opting for BlueCharge.
                  </Typography>
                  <Typography
                    color="#4285F4"
                    fontStyle="italic"
                    fontSize={matches ? 20 : 24}
                    fontWeight={500}
                  >
                    {" "}
                    Be smart while you step out.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
