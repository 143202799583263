import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useNavigate,
} from "react-router-dom";

import "./App.css";
import Homepage from "./pages/home";
import VenuePage from "./pages/venues";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/venues" element={<VenuePage />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/term-of-use" element={<Terms />} />
        </Routes>
        <Outlet />
      </Router>
    </div>
  );
}

export default App;
