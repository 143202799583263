export const Nav = [
  {
    label: "How it Works",
    icon: "",
    href: "/#howitworks",
  },
  {
    label: "Why Blue charge ?",
    icon: "",
    href: "/#whybluecharge",
  },
  {
    label: "Specifications",
    icon: "",
    href: "/#specification",
  },
  {
    label: "Plans",
    icon: "",
    href: "/#plan",
  },
  {
    label: "Venues",
    icon: "",
    href: "/venues",
  },
];
